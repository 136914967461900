import type { IndividualDataMinimalProduct } from '../../stores/individual.types'
import { ProductType } from '../../types/product.types'
import useIndividualProductData from './useIndividualProductData'
import type { Maybe, Scalars } from '#gql/default'

export type StockQuantityLabelProduct = IndividualDataMinimalProduct & { sales_um?: string | null, product_typename?: Maybe<Scalars['String']['output']> }

/**
 * Get product stock quantity label
 * @description Real data only for logged in customers. For guests we are returning default values.
 * @param {ProductInterfaceExtended} product
 */

function useProductStockQuantityLabel(product: StockQuantityLabelProduct) {
  const { getProductQty } = useIndividualProductData()

  const individualStockQuantityLabel = computed((): string => {
    if (!product || product?.product_typename === ProductType.VIRTUAL) {
      return ''
    }

    const { $i18n } = useNuxtApp()
    const { t } = $i18n
    const individualQty = getProductQty(product)?.qty

    if (individualQty == null || (typeof individualQty === 'number' && individualQty <= 0)) {
      return ''
    }
    return `${individualQty} ${product.sales_um ?? t('ecom_pck')}`
  })

  return individualStockQuantityLabel
}

export default useProductStockQuantityLabel
