import { mapProductsAttributesList } from '@ecom/helpers/products'
import useIndividualProductData from '@ecom/composables/product/useIndividualProductData'
import useProductStockQuantityLabel from '@ecom/composables/product/useProductStockQuantityLabel'
import type { StockQuantityLabelProduct } from '@ecom/composables/product/useProductStockQuantityLabel'
import type { RelatedProductsQueryUpsellProductsItems, UpsellProductsQueryUpsellProductsItems } from '@ecom/types/types'
import { ProductType } from '@ecom/types/product.types'
import { useProductQueries } from './product/useProductQueries/useProductQueries'
import type { RelatedProductsQueryVariables, UpsellProductsQueryVariables } from '#gql'

export function useProduct() {
  const relatedProductsLoadingItems = ref<string[]>([])
  const upsellProductsLoadingItems = ref<string[]>([])
  const relatedProducts = ref<{
    [key: string]: RelatedProductsQueryUpsellProductsItems
  }>({})
  const upsellProducts = ref<{ [key: string]: UpsellProductsQueryUpsellProductsItems }>(
    {},
  )

  const getProduct = async (
    urlKey?: string,
    selectedOptionsUids?: string[],
  ) => {
    const attributesList = [
      'certificates',
      'brand',
      'tax_class_id',
      'labels_ecommerce',
      'product_pos_color',
      'ecommerce_packing_type',
      'erp_quantity_in_package',
      'oryginal_unit',
      'applications',
      'features_and_benefits_01',
      'features_and_benefits_02',
      'features_and_benefits_03',
      'features_and_benefits_04',
      'features_and_benefits_05',
      'features_and_benefits_06',
      'features_and_benefits_07',
      'features_and_benefits_08',
      'features_and_benefits_09',
      'features_and_benefits_10',
      'features_and_benefits_picture_1',
      'features_and_benefits_picture_2_4',
      'product_technical_library',
      'product_library_product_card',
      'product_library_catalogs',
      'product_library_product_catalog',
      'product_library_pos',
      'product_library',
      'installation_steps',
      'base_material',
      'base_material_also',
      'applications',
      'connected_services_1',
      'connected_services_2',
      'connected_services_3',
      'connected_services_4',
      'installation_movie',
      'asset_technical_drawing',
      'asset_external_link',
    ]

    const { product } = useProductQueries()

    return await product({
      urlKey: urlKey ?? '',
      selectedOptionsUids,
      attributes: mapProductsAttributesList(attributesList),
    })
  }

  const getRelatedProducts = async (
    variables: RelatedProductsQueryVariables,
  ) => {
    const attributesList = [
      'brand',
      'ecommerce_packing_type',
      'oryginal_unit',
      'erp_packaging_and_quantity',
    ]

    const { relatedProducts } = useProductQueries()

    return await relatedProducts({
      ...variables,
      attributes: mapProductsAttributesList(attributesList),
    })
  }

  const getUpsellProducts = async (variables: UpsellProductsQueryVariables) => {
    const attributesList = [
      'brand',
      'ecommerce_packing_type',
      'oryginal_unit',
      'erp_packaging_and_quantity',
    ]

    const { upsellProducts } = useProductQueries()
    return await upsellProducts({
      ...variables,
      attributes: mapProductsAttributesList(attributesList),
    })
  }

  const getProductStockQuantityLabel = (product: StockQuantityLabelProduct) => {
    if (!product || product.product_typename === ProductType.VIRTUAL) {
      return ''
    }

    const { $i18n } = useNuxtApp()
    const { t } = $i18n

    const stockQuantityLabel = useProductStockQuantityLabel(product)

    if (!stockQuantityLabel?.value) {
      return ''
    }
    return `${t(
      'ecom_in_stock',
    )}:  <span class="font-bold">${stockQuantityLabel.value}
    </span>`
  }

  const handleShowRelatedProducts = async (
    sku: string,
    pageSize: number = 3,
    currentPage: number = 1,
    checkLogin: boolean = true,
  ) => {
    if (checkLogin) {
      const { isLoggedIn } = useCustomer()
      if (!isLoggedIn.value) {
        return
      }
    }
    if (!sku) {
      return
    }
    relatedProductsLoadingItems.value.push(sku)
    try {
      const data = await getRelatedProducts({
        productSku: sku,
        pageSize,
        currentPage,
      })

      const relatedProductsSimpleProducts
        = data?.relatedProducts?.items?.filter(
          item => item?.__typename === 'SimpleProduct',
        ) ?? []

      if (relatedProductsSimpleProducts?.length) {
        const { getIndividualProductsData } = useIndividualProductData()
        await getIndividualProductsData(relatedProductsSimpleProducts)

        Object.assign(relatedProducts.value, {
          [sku]: [
            ...(relatedProducts.value[sku] ?? []),
            ...relatedProductsSimpleProducts,
          ],
        })

        return {
          products: relatedProducts.value,
          pagination: {
            totalItems: data.relatedProducts?.total_count,
          },
        }
      }

      Object.assign(relatedProducts.value, {
        [sku]: [],
      })

      return {
        products: relatedProducts.value,
        pagination: {
          totalItems: data.relatedProducts?.total_count ?? pageSize,
        },
      }
    }
    finally {
      relatedProductsLoadingItems.value
        = relatedProductsLoadingItems.value.filter(
          loadingItem => loadingItem !== sku,
        )
    }
  }

  const handleShowUpsellProducts = async (
    sku: string,
    pageSize: number = 3,
    currentPage: number = 1,
    checkLogin: boolean = true,
  ) => {
    if (checkLogin) {
      const { isLoggedIn } = useCustomer()
      if (!isLoggedIn.value) {
        return
      }
    }
    if (!sku) {
      return
    }
    upsellProductsLoadingItems.value.push(sku)
    try {
      const data = await getUpsellProducts({
        productSku: sku,
        pageSize,
        currentPage,
      })

      const upsellProductsSimpleProducts
        = data?.upsellProducts?.items?.filter(
          item => item?.__typename === 'SimpleProduct',
        ) ?? []

      if (upsellProductsSimpleProducts?.length) {
        const { getIndividualProductsData } = useIndividualProductData()
        await getIndividualProductsData(upsellProductsSimpleProducts)

        Object.assign(upsellProducts.value, {
          [sku]: [
            ...(upsellProducts.value[sku] ?? []),
            ...upsellProductsSimpleProducts,
          ],
        })
        return {
          products: upsellProducts.value,
          pagination: { totalItems: data.upsellProducts?.total_count },
        }
      }

      Object.assign(upsellProducts.value, {
        [sku]: [],
      })

      return {
        products: upsellProducts.value,
        pagination: { totalItems: data.upsellProducts?.total_count },
      }
    }
    finally {
      upsellProductsLoadingItems.value
        = upsellProductsLoadingItems.value.filter(
          loadingItem => loadingItem !== sku,
        )
    }
  }

  const resetRelatedProductsForProduct = (sku: string) => {
    if (!sku || !relatedProducts.value?.[sku]) {
      return
    }

    relatedProducts.value[sku] = []
  }

  const resetUpsellProductsForProduct = (sku: string) => {
    if (!sku || !upsellProducts.value?.[sku]) {
      return
    }

    upsellProducts.value[sku] = []
  }

  return {
    getProduct,
    getRelatedProducts,
    getUpsellProducts,
    getProductStockQuantityLabel,
    relatedProductsLoadingItems,
    handleShowRelatedProducts,
    handleShowUpsellProducts,
    relatedProducts,
    upsellProducts,
    upsellProductsLoadingItems,
    resetRelatedProductsForProduct,
    resetUpsellProductsForProduct,
  }
}
